import { Component, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';

import { Router } from '@angular/router';
import { AuthService } from './shared/services/auth.service';

import { MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { UserProfileService } from './shared/services/api/user-profile.service';
import { CheckAppVersionService } from './shared/services/build-details/check-app-version.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    constructor(
        private router: Router,
        private authService: AuthService,
        private broadcastService: MsalBroadcastService,
        private userProfileService: UserProfileService,
        private checkAppVersionService: CheckAppVersionService
    ) {
        this.checkAppVersionService.init();
    }

    async ngOnInit() {
        this.broadcastService.msalSubject$
            .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
            .subscribe((result: EventMessage) => {
                this.authService.setUserDataAccess(result.payload);
                this.userProfileService.logUserAccess().subscribe();
                this.router.navigate(['/']);
            });
    }
}
